
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import type { ReportingAuditLogs } from '@/definitions/reporting/types'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmBadge from '@/components/shared/TmBadge.vue'

export default defineComponent({
  name: 'EventSummaryAuditLogsModal',
  components: { TmBadge, TmCountry, TmPerson, TmStatus, DetailsTable, TmButton, TmModal },
  props: {
    item: {
      type: Object as PropType<ReportingAuditLogs>,
      required: true,
    },
  },
  setup(props) {
    const items: any[] = [
      { label: 'Event name', value: props.item.action.name },
      { label: 'Category', value: props.item.category },
      { label: 'Source', value: 'Web app' },
      { label: 'Number', value: '+37259122132' },
      { label: 'Cost', value: '$3.12' },
      { label: 'Country', value: { id: 'us', name: 'United States' } },
    ]
    if (props.item.account) {
      items.push({ label: 'Assignee', value: props.item.account })
    }

    return {
      items,
    }
  },
})
